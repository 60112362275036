import { toast } from 'react-toastify';
import Logger from '@services/logger/loggerService';
import ApiService from '../apiService';

export const submitQuote = async formData => {
  try {
    const { emailAddress, zipCode, zipPostalCode, postalCode, dealerId } =
      formData;
    const zip = zipCode || zipPostalCode || postalCode;
    const isValidZip = await validateZipCode(zip, true);

    if (isValidZip) {
      const region = identifyPostalCode(zip);
      if (region === 'CA') {
        typeof zipCode !== 'undefined'
          ? (formData.zipCode = zip.substring(0, 3).toUpperCase())
          : (formData.postalCode = zip.substring(0, 3).toUpperCase());
      }

      const quoteData = {
        email: emailAddress,
        address:
          region === 'CA'
            ? { zip: zip.substring(0, 3).toUpperCase() }
            : { zip },
        sourceSystem: 'kohlerenergy',
        destinationSystem: 'alta',
        ...formData
      };
      const response = await ApiService.post(
        '/crm/webtocase?channel=energy',
        quoteData
      );
      response.message = 'Success';
      response.detail = 'A Lead has been created.';
      return response;
    }

    return {
      message: 'Bad request',
      detail: 'Invalid zip code.'
    };
  } catch (error) {
    toast.error(error);
    throw error;
  }
};

function isValidPostalCode(postalCode) {
  const regex =
    /^(?:\d{5}(?:-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i;
  return regex.test(postalCode);
}

function identifyPostalCode(postalCode) {
  if (!isValidPostalCode(postalCode)) {
    return 'invalid';
  }

  const usRegex = /^\d{5}(-\d{4})?$/;
  if (usRegex.test(postalCode)) {
    return 'US';
  } else {
    return 'CA';
  }
}

const countryRegionMap = {
  US: 'United States',
  CA: 'Canada'
};

function formatCanadianPostalCode(postalCode) {
  if (postalCode.length === 6) {
    return postalCode.slice(0, 3) + ' ' + postalCode.slice(3);
  }
  return postalCode; // if its not 6 characters log then assume its already correct
}

const validateZipCode = async (zipCode, isDealerSpecific = false) => {
  const region = identifyPostalCode(zipCode);
  if (region === 'invalid') {
    return false; // Invalid zip code
  }

  if (region === 'CA') {
    zipCode = formatCanadianPostalCode(zipCode);
  }

  const bingMapsKey = process.env.NEXT_PUBLIC_BING_MAPS;
  const geocodeRequest = `https://dev.virtualearth.net/REST/v1/Locations/${region}/${encodeURIComponent(
    zipCode
  )}?key=${bingMapsKey}`;

  try {
    const response = await fetch(geocodeRequest);
    const data = await response.json();

    if (data?.resourceSets?.[0]?.resources?.length > 0) {
      const resource = data.resourceSets[0].resources[0];
      const regionMatch =
        resource?.address?.countryRegion === countryRegionMap[region];
      if (
        (isDealerSpecific && regionMatch) ||
        (!isDealerSpecific &&
          regionMatch &&
          resource.address.adminDistrict &&
          resource.address.locality &&
          resource.address.postalCode)
      ) {
        return true;
      }
    }
    return false;
  } catch (error) {
    Logger.error('Error: ', error);
    return false;
  }
};
