import React, { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

/**
 * @class
 * @param {Object} props All other props
 * @param {string} variant Specifies variant of button
 * @param {Object} icon Icon on button
 * @param {Object} children Children in the button
 * @param {Boolean} loading Prop to define loading state of loader of button default value if false
 * @param {Function} onClick Handles click event
 * @param {string} className Additional classes
 *
 * @returns Button Component as per the variant
 */
const Button = (
  {
    variant,
    type = 'button',
    className,
    icon,
    children,
    loading = false,
    onClick,
    hideLoader = false,
    name = 'button',
    disabled,
    readOnly,
    ...props
  },
  ref
) => {
  const primary = variant === 'primary' && !props?.disabled && !props?.readOnly;

  const secondary =
    variant === 'secondary' && !props?.disabled && !props?.readOnly;

  const primaryBlack =
    variant === 'primary-black' && !props?.disabled && !props?.readOnly;

  const secondaryBlack =
    variant === 'secondary-black' && !props?.disabled && !props?.readOnly;

  const isDefault =
    variant === 'default' && !props?.disabled && !props?.readOnly;

  const danger = variant === 'danger' && !props?.disabled && !props?.readOnly;

  const link = variant === 'link' && !props?.disabled && !props?.readOnly;

  const isDisabled = props?.disabled;

  const finalClasses = cx(
    'uppercase',
    'cursor-pointer',
    'shadow-none hover:shadow-lg',
    'transition',
    'ease-in-out',
    'text-sm',
    'duration-150',
    'focus:ring-0',
    'leading-normal',
    // 'hover:scale-95',
    'no-underline',
    { 'focus:outline-none hover:shadow-none': link },
    {
      'min-w-[128px] px-4 py-4 active:shadow-lg focus:shadow-lg hover:shadow-lg':
        children && !link
    },
    {
      'bg-primary text-white ': primary
    },
    {
      'bg-black text-white border border-solid border-white': primaryBlack
    },
    {
      'bg-white border border-solid text-black border-black text-md':
        secondaryBlack
    },
    {
      'bg-white text-primary  text-md': secondary
    },
    {
      'bg-white text-base text-extra text-md': isDefault
    },
    {
      'bg-white text-danger  text-md': danger
    },
    {
      'bg-inherit border-none shadow-none hover:underline focus:underline active:underline active:bg-blue-700 hover:bg-blue-700 focus:bg-blue-700px-2 py-4':
        link
    },
    { 'cursor-not-allowed': isDisabled },
    className
  );

  return (
    <button
      type={type}
      className={finalClasses}
      ref={ref}
      disabled={loading}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

Button.PropTypes = {
  variant: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
  onclick: PropTypes.func,
  hideLoader: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default forwardRef(Button);
