import React, { forwardRef } from 'react';
import cx from 'classnames';
import RichText from '@components/richtext';

const Checkbox = (
  {
    label: checkboxLabel,
    name,
    classNames = {},
    checked,
    error,
    urlTarget = '',
    ...rest
  },
  ref
) => {
  const inputClasses =
    'w-8 h-5 text-black rounded focus:ring-black border-black mr-1 checked:accent-black';
  const labelClasses =
    'inline-flex ml-2 text-sm font-medium text-gray-900 select-none my-[7px]';
  const errorClasses = cx('text-danger', 'text-sm', 'ml-1', 'pb-2', 'mt-2');

  return (
    <>
      <div className='flex my-2'>
        <label
          htmlFor={name}
          aria-label={name}
          aria-hidden={true}
          aria-checked={checked}
          className={cx(labelClasses, classNames?.label, {
            'cursor-pointer': !rest.disabled
          })}
        >
          <input
            type='checkbox'
            data-testid='Checkbox'
            id={name}
            name={name}
            className={cx(inputClasses, classNames?.input, {
              'cursor-pointer': !rest.disabled
            })}
            {...rest}
            defaultChecked={checked}
            checked={rest?.value}
            // role='checkbox'
            // aria-checked={rest?.value}
            ref={ref}
          />
        </label>
        {checkboxLabel && typeof checkboxLabel !== 'object' ? (
          <label className={cx('pt-1.5 text-sm', classNames?.label)}>
            {checkboxLabel}
          </label>
        ) : (
          <RichText
            text={checkboxLabel}
            classNames={{
              container: 'font-proLight text-sm pt-1',
              anchorClasses: classNames?.anchorClasses
            }}
            urlTarget={urlTarget}
          />
        )}
      </div>
      {error ? (
        <div
          id='error_desc'
          data-testid='checkbox-errors'
          className={errorClasses}
        >
          {error}
        </div>
      ) : null}
    </>
  );
};
export default forwardRef(Checkbox);
