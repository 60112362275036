import { useState, useEffect } from 'react';

// {This Component is used for remove extra <h1> tags}
const useIsMobile = props => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      props === 'ProductDetailsPage'
        ? setIsMobile(window.innerWidth <= 767)
        : setIsMobile(window.innerWidth <= 1024);
    };
    // Initial check
    checkIsMobile();
    // Add event listener
    window.addEventListener('resize', checkIsMobile);
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);
  return isMobile;
};
export default useIsMobile;
