import React, { useRef, forwardRef } from 'react';
import cx from 'classnames';
import { onEnterSpacePress } from '@utils/keyboard';

const TextInput = (
  {
    value,
    id,
    type = 'text',
    onChange,
    inputClassNames = '',
    labelClassNames = '',
    onKeyDown,
    label,
    readOnly = false,
    disabled = false,
    name,
    placeholder = ' ',
    error,
    errorMessage = '',
    onFocus,
    onBlur,
    onClick,
    loading
  },
  ref
) => {
  const altRef = useRef();
  const inputRef = ref || altRef;

  const inputClasses = cx(
    inputClassNames,
    'block',
    'h-12',
    'w-full',
    'px-3',
    'py-1',
    'xl:pt-4',
    'border-gray-600',
    'text-black',
    'align-bottom',
    'bg-white',
    'appearance-none',
    'focus:outline-none',
    'focus:ring-1',
    'placeholder:text-transparent',
    'peer',
    { 'text-black': !disabled },
    { 'text-gray-400 cursor-not-allowed': disabled },
    {
      'border border-red-600 focus:border-red-600  focus:ring-red-600': error
    },
    {
      'border border-greish focus:border-primary focus:ring-primary':
        !error && !disabled
    }
  );
  const labelClasses = cx(
    labelClassNames,
    'z-0',
    'px-[15px]',
    'w-full',
    'absolute',
    'text-black',
    'dp-sm-text-label',
    'duration-300',
    'transform',
    'scale-75',
    'top-[30%]',
    'peer-valid:bg-red',
    'peer-focus:top-[29%]',
    'origin-[0]',
    'peer-placeholder-shown:scale-100',
    '-translate-y-4',
    'peer-placeholder-shown:translate-y-0',
    'peer-focus:scale-75',
    'peer-focus:-translate-y-4',
    'xl:w-[90%]',
    { 'cursor-text': !disabled },
    { 'text-gray-400 cursor-not-allowed': disabled }
  );

  const errorClasses = cx('text-danger', 'text-sm', 'ml-1', 'pb-2', 'mt-2');

  return (
    <>
      <div className='relative flex mb-2'>
        <input
          type={type}
          data-testid={name}
          id={id ?? name}
          name={name}
          placeholder={placeholder}
          className={inputClasses}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          ref={inputRef}
          aria-invalid={!!error}
          aria-describedby='error_desc'
          onFocus={onFocus ?? null}
          onBlur={onBlur ?? null}
          onClick={onClick ?? null}
          htmlFor={id ?? name}
        />
        <label
          htmlFor={id ?? name}
          data-testid={'input-label'}
          className={labelClasses}
          onClick={() => inputRef?.current?.focus()}
          onKeyDown={onEnterSpacePress(() => inputRef?.current?.focus())}
        >
          {label}
        </label>
      </div>
      {error ? (
        <div
          id='error_desc'
          data-testid='textinput-errors'
          className={errorClasses}
        >
          {errorMessage || error}
        </div>
      ) : null}
    </>
  );
};

export default forwardRef(TextInput);
