import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Tooltip = ({
  id = 'tooltip-info',
  className,
  content,
  children,
  contentPrefix
}) => {
  return (
    <div className='group relative flex -mt-0.5' data-testid='Tooltip'>
      <button
        aria-label={contentPrefix}
        aria-describedby={id}
        className='bg-transparent border-0 h-5 m-0 p-0'
      >
        {children}
      </button>
      <span
        role='tooltip'
        id={id}
        className={classNames(
          'absolute',
          'font-light',
          'text-[13px]',
          'shadow-lg',
          'scale-0',
          'transition-all',
          'rounded',
          'bg-white',
          'p-4',
          'text-xs',
          'group-hover:scale-100',
          'group-focus-within:scale-100',
          'top-[25px]',
          'max-w-[250px]',
          'left-0',
          'whitespace-pre-wrap',
          'z-50',
          className
        )}
      >
        {content}
      </span>
    </div>
  );
};

Tooltip.PropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  contentPrefix: PropTypes.string.isRequired
};

export default Tooltip;
